import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Alert, Badge, Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { Option as OptionType } from "types/graphql";

import {
  concatTecAggregationIntegrationTypeName,
  hasEnabledPikaichiConfig,
  hasEnabledTecAggregationConfig,
  hasEnabledWinboardConfig,
} from "../biToolConfig";

import { useOptionHeaderGetBiOptionsQuery } from "./queries";

type Props = {
  option: Pick<OptionType, "name" | "optionId"> | null;
  onBack: () => void;
  shouldShowManagingShopAlert?: boolean;
};

export const OptionHeader = memo<Props>(({ option, onBack, shouldShowManagingShopAlert }) => {
  const { pathname } = useLocation();
  const { canAccess } = useCanAccess();
  const [company] = useCompany();
  const shops = company?.shops ?? [];

  const selectedKey = pathname.split("/")[3];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  const isWinboardEnabled =
    canAccess("editOptionWinboardOption") && hasEnabledWinboardConfig(shops);

  const isPikaichiEnabled =
    canAccess("editOptionPikaichiOption") && hasEnabledPikaichiConfig(shops);

  const isTecAggregationEnabled =
    canAccess("editOptionTecAggregationOption") && hasEnabledTecAggregationConfig(shops);

  const { data: getOptionBiData } = useOptionHeaderGetBiOptionsQuery(
    option?.optionId
      ? {
          variables: {
            optionId: option.optionId,
            isPikaichiEnabled,
            isTecAggregationEnabled,
            isWinboardEnabled,
          },
        }
      : { skip: true },
  );

  const pikaichiOption = getOptionBiData?.option?.flatMap((option) =>
    option.choices.flatMap((choice) => choice.pikaichiMenuChoices?.[0]?.pikaichiMenu),
  );

  const tecAggregationOption = getOptionBiData?.option?.flatMap((option) =>
    option.choices.flatMap((choice) => choice.tecAggregationMenu),
  );
  const winboardOption = getOptionBiData?.option?.flatMap((option) =>
    option.choices.flatMap((choice) => choice.winboardMenus?.[0]),
  );

  const tecAggregationIntegrationTypeName = concatTecAggregationIntegrationTypeName(shops);

  return (
    <>
      <PageHeader onBack={onBack} title={option?.name ?? ""} />

      {shouldShowManagingShopAlert && (
        <>
          <Alert
            type="warning"
            message="管理外の店舗のオプションに影響を与える可能性があります。注意して編集してください。"
          />

          <Spacer size={16} />
        </>
      )}

      {option && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/option/${option.optionId}/edit`} replace>
              基本情報
            </Link>
          </Menu.Item>
          <Menu.Item key="translation">
            <Link to={`/option/${option.optionId}/translation/edit`} replace>
              多言語設定
            </Link>
          </Menu.Item>
          <Menu.Item key="dealer">
            <Link to={`/option/${option.optionId}/dealer/edit`} replace>
              印刷設定
            </Link>
          </Menu.Item>
          {isPikaichiEnabled && (
            <Menu.Item key="pikaichi">
              <Link to={`/option/${option.optionId}/pikaichi/edit`} replace>
                ぴかいちナビ連携
                {pikaichiOption?.includes(undefined) && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Link>
            </Menu.Item>
          )}
          {isTecAggregationEnabled && (
            <Menu.Item key="tec">
              <Link to={`/option/${option.optionId}/tec/edit`} replace>
                {tecAggregationIntegrationTypeName ? tecAggregationIntegrationTypeName : "-"}
                {tecAggregationOption?.includes(undefined || null) && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Link>
            </Menu.Item>
          )}
          {isWinboardEnabled && (
            <Menu.Item key="winboard">
              <Link to={`/option/${option.optionId}/winboard/edit`} replace>
                ウィンボード連携
                {winboardOption?.includes(undefined) && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Link>
            </Menu.Item>
          )}
        </Menu>
      )}
    </>
  );
});
